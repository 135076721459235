import React, { useEffect, useState } from 'react';
import Client from 'shopify-buy';
import { Button, ButtonGroup, Icon, IconButton } from '@chakra-ui/react'
import Navbar from '../../components/NavBar';
import { navigate } from 'gatsby';
import { AddIcon, DeleteIcon, MinusIcon } from '@chakra-ui/icons';
import MarketingFooter from '../../components/MarketingFooter';

const Stickers = () => {
  const [products, setProducts] = useState([])
  const [cart, setCart] = useState(null)
  const client = Client.buildClient({
    domain: 'shop.getkodama.com',
    storefrontAccessToken: 'cb2a0e5e90a6e9879259555e6681fffe'
  });
  useEffect(() => {
    client.product.fetchAll().then((products) => {
      // Do something with the products

      setProducts(products)
      console.log(products[0]);
    });
    const id = localStorage.getItem("cart")
    if (id == null) {
      client.checkout.create().then((checkout) => {
        // Do something with the checkout
        setCart(checkout);
        localStorage.setItem("cart", checkout.id)
        console.log(checkout);
      });
    } else {
      client.checkout.fetch(id).then((checkout) => {
        // Do something with the checkout
        setCart(checkout);
        console.log(checkout);
      });
    }
  }, [])

  const addOne = (lineItems) => {
    const toUpdate = lineItems.map(id => ({
        id, quantity: cart.lineItems.find(li => li.id == id).quantity + 1
    }))
    client.checkout.updateLineItems(cart.id, toUpdate).then(checkout => {
        setCart(checkout)
    })
  }
  const removeOne = (lineItems) => {
      const toUpdate = lineItems.map(id => ({
          id, quantity: cart.lineItems.find(li => li.id == id).quantity - 1
      }))
      client.checkout.updateLineItems(cart.id, toUpdate).then(checkout => {
          setCart(checkout)
      })
  }
  const removeAll = (lineItems) => {
    client.checkout.removeLineItems(cart.id, lineItems).then(checkout => {
        setCart(checkout)
    })
  }
  
  return (
      <>
        <div className={'bg-hero-image bg-cover p-1 text-white'}>
          <Navbar /> 
          <div className="container xl:px-40 mt-10 mb-8 mx-auto grid grid-cols-1 lg:grid-cols-2 items-top">
              <div className="min-h-96 p-2 w-full">
                  <p className="text-5xl mb-8 font-bold">Your Shopping Cart</p>
              </div>
              <div className="p-2 flex">
                <div className="lg:flex-grow"></div>
                <Button onClick={() => navigate("/stickers/cart")} variant="outline" colorScheme="white" className="flex-grow lg:flex-grow-0 lg:flex-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  { cart && cart.lineItems.reduce((p, c) => p + c.quantity, 0) }
                </Button>
              </div>
          </div> 
        </div>
        <div className="container xl:px-40 mb-5 mt-8 mx-auto px-4 lg:px-0">
          { cart && cart.lineItems.map(item => (
            <div className="flex items-center space-x-2 mb-4 p-2 border border-gray-300 shadow-lg rounded-lg" key={item.id}>
                <div>
                    <img className="h-32" src={item.variant.image.src} alt={item.variant.image.altText} />
                </div>
                <div>
                    <div className="text-lg font-bold">{item.title} {item.variant.title}</div>
                    <div className="flex items-center space-x-2 mt-2">
                        <ButtonGroup size="sm" isAttached variant="outline">
                            <IconButton onClick={() => removeOne([item.id])} aria-label="Subtract" icon={<MinusIcon />} />
                            <Button>{item.quantity}</Button>
                            <IconButton onClick={() => addOne([item.id])} aria-label="Add" icon={<AddIcon />} />
                        </ButtonGroup>
                        <IconButton onClick={() => removeAll([item.id])} size="sm" variant="outlined" aria-label="Remove" icon={<DeleteIcon />} />
                    </div>
                    <div className="text-xl font-bold mt-2">
                        ${(parseFloat(item.variant.priceV2.amount) * item.quantity).toFixed(2)}
                    </div>
                </div>
            </div>
          ))}
          <div className="text-xl font-bold">Subtotal: ${cart && parseFloat(cart.lineItemsSubtotalPrice.amount).toFixed(2)}</div>
          <div className="mt-4">
              <Button onClick={() => navigate(cart.webUrl)}>Checkout</Button>
          </div>
        </div>
        <MarketingFooter />
      </>
  )
};

export default Stickers;
